import exibirSubFrm from '#eproc/funcoes/exibirSubfrm'

(function(window) {

    function unformatNumber(pNum) {
        return String(pNum).replace(/\D/g, "").replace(/^0+/, "");
    }

    /**
     * Listener para evitar duplo click nas paginas que submetem dados
     * Obejtiva evitar duplicidade de dados por n submissoes
     * A menos que EXIBIR_DIV_INFRA_BEFORE_UNLOAD seja setado para false, vai exibir o aviso
     *
     */
    window.addEventListener("beforeunload", function(event) {
        var showAviso = true;
        var excel = document.getElementById("hdnExcel");
        showAviso = !excel || excel.value != "1";
        //controlado pelos editores
        try {
            if (EXIBIR_DIV_INFRA_BEFORE_UNLOAD === false) {
                showAviso = false;
            }
        } catch (error) {
            //para paginas que nao definem a variavel
            //seta true, amenos que alguma regra anterior sete false
            showAviso = showAviso && true;
        }

        if (showAviso) {
            infraExibirAviso(false, "Aguarde...");
        }
    });

    window.addEventListener("beforeunload", function(event) {
        if (typeof verificaAlteracoesEditoresBeforeUnload != 'undefined') {
            return verificaAlteracoesEditoresBeforeUnload(event);
        }
    });

    function formatCpfCnpj(pCpfCnpj, pUseSepar, pIsCnpj) {
        var NUM_DIGITOS_CPF = 11;
        var NUM_DIGITOS_CNPJ = 14;
        if (pIsCnpj == null) {
            pIsCnpj = false;
        }
        if (pUseSepar == null) {
            pUseSepar = true;
        }
        var maxDigitos = pIsCnpj ? NUM_DIGITOS_CNPJ : NUM_DIGITOS_CPF;
        var numero = unformatNumber(pCpfCnpj);

        numero = numero.lpad(maxDigitos, '0');

        if (!pUseSepar) {
            return numero;
        }

        if (pIsCnpj) {
            let reCnpj = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
            numero = numero.replace(reCnpj, "$1.$2.$3/$4-$5");
        } else {
            let reCpf = /(\d{3})(\d{3})(\d{3})(\d{2})$/;
            numero = numero.replace(reCpf, "$1.$2.$3-$4");
        }
        return numero;
    }

    function isBlank(val) {
        if (val == null || val == false || val == '' || val == 0 || val.length == 0) {
            return true;
        } else {
            return false;
        }
    }

    function sleep(ms) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > ms) {
                break;
            }
        }
    }

    function bolFilho(objChild, objParent) {
        alvoParent = document.getElementById(objParent);
        //alert(objChild.parentNode.id);
        proximoParent = objChild.parentNode;

        while (proximoParent != undefined) {
            if (proximoParent == document.body) {
                break;
            }

            if (proximoParent.id == alvoParent.id) {
                return true;
            }

            proximoParent = proximoParent.parentNode;
        }

        return false;
    }

    function abrirLink(event) {
        if (navigator.appName == 'Microsoft Internet Explorer') {
            if (event && typeof event.srcElement === "undefined") {
                return;
            }
            if (window.event.srcElement.target == '' && (bolFilho(window.event.srcElement, 'divPainel'))) {
                //alert('processando IE');
                infraExibirAviso(false);
            }
        } else {
            if (event.target.target == '' && (bolFilho(event.target, 'divPainel')) && event.button == 0) {
                //alert('processando FF');
                infraExibirAviso(false);
            }
        }
    }

    function inputCopiarAlvo(
        element,
        regex,
        strTextoTooltipCopiado,
        strTextoTooltipNaoCopiado,
        clicar,
        numTempoExibirTooltip,
        strAtributoValor) {
        var element = infraGetElementById(element);
        var regex = regex || /[^\d]/g;
        var strTextoTooltipCopiado = strTextoTooltipCopiado || 'Número copiado para a área de transferência';
        var strTextoTooltipNaoCopiado = strTextoTooltipNaoCopiado || 'Não foi possivel copiar automaticamente';
        var clicar = clicar || false;
        var numTempoExibirTooltip = numTempoExibirTooltip || 5000;
        var valorOriginal = element.value || element.innerHTML;
        var htmlAntigo = element.innerHTML;

        if (strAtributoValor) {
            valorOriginal = $(element).prop(strAtributoValor);
        }

        var fncMostraInput = function() {

            // Desabilita a função do clique
            infraRemoverEvento(element, 'click', fncMostraInput);

            var bolCopiarParaAreaDeTransferencia = false;

            var width = 'auto';
            var fontSize = '0.88rem';
            var valor = valorOriginal.replace(regex, '') || element.innerHTML.replace(regex, '');
            element.innerHTML = '';

            var strTextoDoTooltip = '';
            var fnTimeoutToolTip;

            if (document.queryCommandSupported('copy')) {
                bolCopiarParaAreaDeTransferencia = true;
                strTextoDoTooltip = strTextoTooltipCopiado;
            } else {
                strTextoDoTooltip = strTextoTooltipNaoCopiado;
            }

            // Cria input com o número do processo
            var inputNumProcesso = document.createElement('input');
            inputNumProcesso.setAttribute('readonly', 'readonly');
            inputNumProcesso.style.border = '0';
            if (width.toString().length > 0) {
                inputNumProcesso.style.width = width;
            }
            if (fontSize.toString().length > 0) {
                inputNumProcesso.style.fontSize = fontSize;
            }
            inputNumProcesso.setAttribute('type', 'text');
            inputNumProcesso.setAttribute('value', valor);

            var tooltipWrap = document.createElement("div"); //creates div
            tooltipWrap.className = 'tooltipAutoSelecionar'; //adds class
            tooltipWrap.appendChild(document.createTextNode(strTextoDoTooltip)); //add the text node to the newly created div.

            var firstChild = document.body.firstChild;//gets the first elem after body
            //firstChild.parentNode.insertBefore(tooltipWrap, firstChild); //adds tt before elem

            // Seleciona texto do input
            inputNumProcesso.onclick = function() {
                inputNumProcesso.focus();
                inputNumProcesso.select();

            };

            inputNumProcesso.onkeypress = function(event) {
                var keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
                if (keyCode == 13) {
                    event.preventDefault();
                }
            };

            // Ação para retornar o html anterior
            inputNumProcesso.onblur = function() {
                element.innerHTML = (element.id == 'spnChaveProcesso') ? valor : htmlAntigo;
                // Adiciona função ao clique
                infraAdicionarEvento(element, 'click', fncMostraInput);
                clearTimeout(fnTimeoutToolTip);
                if (document.querySelector('.tooltipAutoSelecionar')) {
                    document.querySelector('.tooltipAutoSelecionar').remove();
                }
            };

            element.appendChild(inputNumProcesso);
            element.appendChild(tooltipWrap);

            if (clicar) {
                inputNumProcesso.click();
            }

            if (bolCopiarParaAreaDeTransferencia) {
                try {
                    inputNumProcesso.select();
                    document.execCommand("copy");
                } catch (e) {
                    tooltipWrap.className = tooltipWrap.className + ' erro';
                }
            } else {
                tooltipWrap.className = tooltipWrap.className + ' erro';
            }

            fnTimeoutToolTip = window.setTimeout(function() {
                document.querySelector('.tooltipAutoSelecionar').remove();
            }, numTempoExibirTooltip);

            var padding = 5;
            var linkProps = element.getBoundingClientRect();
            var tooltipProps = tooltipWrap.getBoundingClientRect();
            var topPos = linkProps.top - (tooltipProps.height + padding);
            tooltipWrap.setAttribute('style', 'margin-top:-58px;white-space:nowrap');
        };

        // Adiciona função ao clique
        infraAdicionarEvento(element, 'click', fncMostraInput);

    };

    function inputCopiarNumProcesso(element) {
        var element = element;
        var regex = /[^\d]/g;
        var strTextoTooltipCopiado = 'Número copiado para a área de transferência';

        inputCopiarAlvo(element, regex, strTextoTooltipCopiado);
    }

    function inputCopiarTexto(element, strAtributoValor) {
        var element = infraGetElementById(element);
        var regex = /^\s+|\s+$/g;
        var strTextoTooltipCopiado = 'Conteúdo copiado para a área de transferência';

        inputCopiarAlvo(
            element,
            regex,
            strTextoTooltipCopiado,
            undefined,
            true,
            undefined,
            strAtributoValor,
        );
    }

    function copiarConteudoElemetento(idElemento) {
        var elDestino = document.getElementById(idElemento);
        if (!elDestino) {
            alert("O Elemento a ser copiado deve ser informado");
            return;
        }
        var range = new Range();
        range.selectNode(elDestino);
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        alert("Copiado para a área de transferência.");
    }

    //funções para retornar variável da na url
    function retVar(name) {
        var regexp = new RegExp("([\\?&])(" + name + "=)([^&#]*)", "i");
        var result = regexp.exec(window.location.href);
        if (result == null) {
            return "";
        } else {
            return result[3];
        }
    }

    function atualizarSubFrm() {
    }

    function sinalizaEventoCloseSubform() {
        window.parent.document.body.classList.remove('has-open-subfrm');
        if (typeof window.parent.EventoCloseSubform == 'function') {
            window.parent.EventoCloseSubform();
        }
    }


    function ocultarSubFrm(overflowY) {
        var subFrm = window.parent.document.getElementById('ifrSubFrm');

        if (subFrm) {
            sinalizaEventoCloseSubform();
            //reexibe div das abas, se existe
            $(".ui-tabs-nav", top.document).show();
            if (typeof (window.parent.parent.ajustaAltura) == 'function') {
                var func = window.parent.parent.ajustaAltura();
                window.setTimeout(function() {
                    func()
                }, 2000)
            }

            if (typeof overflowY === "undefined") {
                overflowY = 'auto';
            }
            atualizarSubFrm();
            var tipoNavegador = navigator.userAgent.toLowerCase();
            if (tipoNavegador.indexOf('iphone') != '-1' || tipoNavegador.indexOf('ipad') != '-1'
                || tipoNavegador.indexOf('ipod') != '-1') {
                subFrm.removeEventListener('touchmove', touch);
            } else {
                window.parent.document.body.style.overflowY = overflowY;
                subFrm.blur();
            }

            var curWin = parent.document.getElementById('divInfraAvisoFundo');
            $(curWin).remove();
        }
    }

    function ImagemVerificado(idElem) {
        var imgId = 'imgAjaxVerificado' + idElem;
        let imgObj = document.getElementById(imgId);
        if (imgObj != null) {
            return imgObj;
        }
        imgObj = document.createElement('img');
        imgObj.id = imgId;
        imgObj.style.position = 'absolute';
        imgObj.src = 'infra_css/imagens/verificado.gif'
        imgObj.style.visibility = 'hidden';
        document.body.appendChild(imgObj);
        return imgObj
    }

    function adicionarImgVerificado(objAjax) {
        var imgVerificado = ImagemVerificado(objAjax.elem.id);
        objAjax.processarResultadoAntigo = objAjax.processarResultado;
        objAjax.processarResultado = function(id, descricao, complemento) {
            objAjax.processarResultadoAntigo.apply(this);
            infraAjaxOcultarImg(imgVerificado);
            if (!isBlank(id)) {
                infraAjaxMostrarImg(objAjax.elem, imgVerificado, 5, 0);
            }
        }
    }

    function inArray(needle, haystack) {
        var length = haystack.length;
        for (var i = 0; i < length; i++) {
            if (haystack[i] == needle) {
                return true;
            }
        }
        return false;
    }

    function converterTempoEmAnosMesesEDiasParaSegundos(anos, meses, dias) {
        anos = anos * 31556952;
        meses = meses * 2629746;
        dias = dias * 86400;
        return anos + meses + dias;
    }

    function mascaraFormatoEmHoras(elemento, evento) {
        if (window.getSelection) { // Firefox
            var selected_text = elemento.value.substring(
                document.activeElement.selectionStart,
                document.activeElement.selectionEnd,
            );
        } else { // IE
            selected_text = document.selection.createRange().text;
        }

        var charCode, char;
        if (window.event) { // IE
            charCode = evento.keyCode;
        } else { // Netscape/Firefox/Opera
            charCode = evento.which;
        }

        if ((charCode == 8) || (charCode == 0)) {
            return true;
        }

        char = String.fromCharCode(charCode);
        if (isNaN(char) && char != ':') {
            return false;
        } else if (/:/.test(elemento.value)) {
            var aux = elemento.value.split(':');
            if (isNaN(char)
                || (aux[1] != undefined
                    && ((aux[1].length == 0 && parseInt(char) >= 6)
                        || aux[1].length >= 2))
            ) {
                if (parseInt(char) >= 6) {
                    return false;
                }
                if (selected_text) {
                    return true;
                }
            }
        } else if (elemento.value == '' && char == ':') {
            return false;
        }
        return true;
    }

    function montaStrMinHoras(minHoras) {
        var strTexto;
        if (minHoras[0] != 1) {
            strTexto = minHoras[0] + ' horas ';
        } else {
            strTexto = minHoras[0] + ' hora ';
        }

        if (minHoras[1] > 1) {
            strTexto = strTexto + ' e ' + minHoras[1] + ' minutos ';
        } else if (minHoras[1] == 1) {
            strTexto = strTexto + ' e ' + minHoras[1] + ' minuto ';
        }

        return strTexto;
    }

    function ajaxExecRequest(link, params) {
        let objAjaxCarregarNo = new infraAjaxCriarRequest(link);
        objAjaxCarregarNo.open("POST", link, false);
        objAjaxCarregarNo.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        objAjaxCarregarNo.send(params);
        return objAjaxCarregarNo.responseText;
    }

    /*
     * Objeto Eprocjs, atualmente apenas aplicando a funcionalidade do infraEfeitoTabelas mas
     * utilizando o DOM atualizado
     */
    var eprocJs = {
        //Se descomentada, proxima linha habilita consoles do eprocJs.console
        // development: true,

        //Armazenando e centralizando classes usadas nas tabelas
        infraClass: {
            trSelecionada: "infraTrSelecionada",
            trClara: "infraTrClara",
            trEscura: "infraTrEscura",
        },

        getBool: function(toCheck) {
            if (!toCheck) {
                return false;
            }
            var possibleFalse = ["", null, false, "false", 0, "0", undefined, "undefined", "n", "N"];
            if (possibleFalse.indexOf(toCheck.toString()) === -1) {
                return true;
            }
            return false;
        },
        // faz o mesmo que (apenas com tabelas) que o infraEfeitoTabelas, exceto pelo fato de usar o DOM atualizado
        efeitoEmTabelas: function() {
            var self = this;
            $('tr.' + self.infraClass.trClara + ', tr.' + self.infraClass.trEscura).on({
                mouseenter: function() {
                    $(this).addClass(self.infraClass.trSelecionada);
                },
                mouseleave: function() {
                    $(this).removeClass(self.infraClass.trSelecionada);
                },
                mousedown: function() {
                    $(this).addClass(self.infraClass.trSelecionada);
                },
                mouseup: function() {
                    $(this).removeClass(self.infraClass.trSelecionada);
                },
                mouseover: function() {
                    $(this).addClass(self.infraClass.trSelecionada);
                },
                mouseout: function() {
                    $(this).removeClass(self.infraClass.trSelecionada);
                },
            });
        },
        formatarProcessoTRF4: function(numProcesso) {
            var valor = numProcesso.replace(/[^0-9]/gi, '');
            return window.infraFormatarProcessoTrf4(valor);
        },
        console: function(somevar) {
            if (this.development) {
                console.log(somevar);
            }
        },
    };

    /**
     * Centralizador de metodos relacionados a tabela de excluidos manualmente
     */

    eprocJs.objTabelaDesativados = function(listaDesativados, listaDesativar, cpPopularLista, optionsObject) {
        // Atribuindo instancia do jQuery para portabilidade
        var $ = window.jQuery;

        var defaults = {
            mensagens: {
                Erro: 'Não foi possível efetuar a desativação.',
                Confirm: ' Atenção: A pendência será excluída do painel de todos os usuários ativos no processo/inquérito, não sendo possível recuperá-la.\n Caso ela já tenha sido respondida de outra maneira e você deseja fechar, clique em Sim. Confirma?',
                ReuPreso: 'PROCESSO C/ RÉU PRESO',
            },
            linkClasseDesabilitar: 'excluirProcessoComunicacaoExterna',
            cookieName: 'defaultCookieProcessosExcluidos',
            cbComplementarMoverLinha: undefined,
            moverLinhaInfoComplementar: false,
            infraTrPar: 'infraTrClara',
            infraTrImpar: 'infraTrEscura',
            infraTrSelecionada: 'infraTrSelecionada',
            strCaption: {
                Start: 'Lista de ',
                TypePage: 'Processos com Relatório Final',
                Middle: ' (',
                End: ' registros):',
            },
            strCaptionSpan: {
                Before: '(',
                After: ' registros)',
            },
            defaultNumProcess: 'numProcessoFormatado',

            urlRetornarDesativados: 'data-urlCarregamento',
            urlDesativar: 'data-linkassinado',

        };

        var settings = $.extend(true, {}, defaults, optionsObject);

        return {
            elemTarget: $(listaDesativados),
            elemAntigo: $(listaDesativar),
            mensagens: settings.mensagens,
            defaultNumProcess: settings.defaultNumProcess,
            strCaption: settings.strCaption,
            strCaptionSpan: settings.strCaptionSpan,
            linkClasseDesabilitar: settings.linkClasseDesabilitar,
            urlRetornarDesativados: settings.urlRetornarDesativados,
            urlDesativar: settings.urlDesativar,
            moverLinhaInfoComplementar: settings.moverLinhaInfoComplementar,
            infraClasses: {
                par: settings.infraTrPar,
                impar: settings.infraTrImpar,
                selecionada: settings.infraTrSelecionada,
                getSelecionada: function() {
                    return this.selecionada;
                },
                getPar: function() {
                    return this.par;
                },
                getImpar: function() {
                    return this.impar;
                },
                getLinhas: function(comma, elem) {
                    return ((elem) ? elem : '') + this.impar + ((comma) ? ',' : '') + ' ' + ((elem) ? elem : '')
                        + this.par;
                },
                getClasses: function(comma) {
                    return this.par + ((comma) ? ',' : '') + ' ' + this.impar + ((comma) ? ',' : '') + ' '
                        + this.selecionada;
                },
                toString: function() {
                    return this.getClasses();
                },
            },
            getStyleClass: function(numRow) {
                var self = this;
                if (numRow === undefined) {
                    return self.infraClasses.toString();
                }
                return ((numRow % 2) ? self.infraClasses.getImpar() : self.infraClasses.getPar());
            },
            removerOcorrencias: function(item, self) {
                if (item[self.defaultNumProcess]) {
                    var allRows = self.elemAntigo.find('tr');
                    var targetProcess = item[self.defaultNumProcess];

                    $.each(allRows, function(key, row) {
                        var rowContent = $(row).html();
                        var matched = rowContent.search(targetProcess);
                        if (matched > -1) {
                            $(row).remove();
                            eprocJs.efeitoEmTabelas();
                            self.decrementarContador();
                            if (self.elemAntigo.find(self.infraClasses.getLinhas(true, 'tr.')).length == 0) {
                                self.elemAntigo.hide();
                            }
                        }
                    });
                }
            },
            popular: function() {
                var self = this;
                var target = self.elemTarget;
                self.limpar();
                $.getJSON(target.attr(self.urlRetornarDesativados)).done(function(json) {
                    var resultadosTotal = json.length;
                    target.find('caption > span').html(self.getStrCaptionSpan(resultadosTotal));

                    var appendarHtml = '';
                    if (json.length) {
                        $.when(
                            $.each(json, function(key, index) {
                                appendarHtml += cpPopularLista(key, index, self);
                                self.removerOcorrencias(index, self);
                            })).done(function() {
                            target.find('tbody').html(appendarHtml);
                            eprocJs.efeitoEmTabelas();
                            eprocJs.console(target);
                            window.exportando = target;
                            target.show();
                        });
                    }

                }).fail(function(error) {
                    eprocJs.console(error);
                });
                infraCriarCookie(settings.cookieName, 'true');
            },
            limpar: function() {
                var self = this;
                self.elemTarget.find('tbody').html('');
                self.elemTarget.find('caption > span').html('');
                self.elemTarget.hide();
                eprocJs.efeitoEmTabelas();
                infraRemoverCookie(settings.cookieName);
            },
            atualizarTabela: function() {
                var self = this;
                self.elemAntigo
                    .find(self.infraClasses.getLinhas(true, 'tr.'))
                    .removeClass(self.infraClasses.getClasses())
                    .addClass(function(index) {
                        return self.getStyleClass(index);
                    });
            },
            incrementarContador: function() {
                var self = this;
                var total = self.elemTarget.find(self.infraClasses.getLinhas(true, 'tr.')).length;
                self.elemTarget.find('caption > span').html(this.getStrCaptionSpan(total));
            },
            getStrCaption: function(total) {
                eprocJs.console(this);
                eprocJs.console(this.strCaption);
                return this.strCaption.Start + this.strCaption.TypePage + this.strCaption.Middle + ((total)
                    ? total
                    : '0') + this.strCaption.End;
            },
            getStrCaptionSpan: function(total) {
                eprocJs.console(this);
                eprocJs.console(this.strCaptionSpan);
                return this.strCaptionSpan.Before + ((total) ? total : '0') + this.strCaptionSpan.After;
            },
            decrementarContador: function() {
                var self = this;
                var total = self.elemAntigo.find(self.infraClasses.getLinhas(true, 'tr.')).length;
                self.elemAntigo.find('caption').html(self.getStrCaption(total));
            },
            gerenciarContadores: function() {
                var self = this;
                self.incrementarContador();
                self.decrementarContador();
            },
            moverLinha: function(linhaAlvo, infoRegistro, cbEditRow, cbAfterRemove, cbAfterMove) {
                var self = this;
                linhaAlvo.hide('fast', function() {
                    $(this).find('td>a.' + self.linkClasseDesabilitar).parent().remove();
                    var linhaAlvo = $(this);
                    if (settings.cbComplementarMoverLinha != undefined) {
                        linhaAlvo = settings.cbComplementarMoverLinha(linhaAlvo);
                    } else if (cbEditRow != undefined) {
                        linhaAlvo = cbEditRow(linhaAlvo);
                    }
                    if (cbAfterMove != undefined) {
                        linhaAlvo = cbAfterMove(linhaAlvo);
                    }

                    var clonedTr = linhaAlvo.clone();
                    $(this).remove();
                    if (cbAfterRemove != undefined) {
                        cbAfterRemove();
                    }
                    if (self.elemAntigo.find(self.infraClasses.getLinhas(true, 'tr.')).length == 0) {
                        self.elemAntigo.hide();
                    }
                    if (self.elemTarget.find(self.infraClasses.getLinhas(true, 'tr.')).length > 0) {
                        if (self.moverLinhaInfoComplementar == true) {
                            if (infoRegistro != undefined) {
                                clonedTr = $(cpPopularLista(0, infoRegistro, self));
                            } else {
                                self.popular();
                                return false;
                            }
                        }
                        clonedTr.removeClass(self.infraClasses.getSelecionada()).removeClass(self.infraClasses.getImpar()).removeClass(
                            self.infraClasses.getPar());
                        clonedTr.addClass(((self.elemTarget.find('tbody > tr:last-child').hasClass(self.infraClasses.getImpar())
                            ? self.infraClasses.getPar()
                            : self.infraClasses.getImpar()))).css("display", "none");
                        self.elemTarget.find('tbody').append(clonedTr).find('tr:last-child').show('fast');
                        self.atualizarTabela();
                        self.gerenciarContadores();
                    }
                    self.gerenciarContadores();
                    if (self.elemTarget.is(':hidden') && infraLerCookie(settings.cookieName) == 'true') {
                        if (self.elemTarget.find(self.infraClasses.getLinhas(true, 'tr.')).length == 0) {
                            self.popular();
                        }
                        self.elemTarget.show('fast');
                    }
                    self.atualizarTabela();
                    eprocJs.efeitoEmTabelas();
                });
            },
            acaoDesativarManualmente: function(e, jqElement, cbEditRow, cbAfterRemove) {
                e.preventDefault();
                var self = this;
                if (!confirm(self.mensagens.Confirm)) {
                    return false;
                }

                var trTarget = $(jqElement).closest('tr');
                var linkAssinado = $(jqElement).attr(self.urlDesativar);
                $.getJSON(linkAssinado).done(function(json) {
                    if (json.sucesso == 1 || json.sucesso == true || json.sucesso == "true") {
                        self.moverLinha(trTarget, json.infoRegistro, cbEditRow, cbAfterRemove);
                        eprocJs.console('Sucesso');
                    } else {
                        eprocJs.console('erro');
                        alert(self.mensagens.Erro);
                    }
                }).fail(function(error) {
                    eprocJs.console('erro');
                    eprocJs.console(error);
                    alert(self.mensagens.Erro);
                });
                return false;
            },
        };
    };

    // Pedido 201189: altera regras para obter senhas mais fortes de usuários internos
    function validarNovaSenha(
        strSenha,
        boolUsuarioInterno,
        boolAdministrador,
        numTamMinExterno,
        numTamMinInterno,
        numTamMinAdministrador) {

        var numTamanhoMinimoSenha = numTamMinExterno || 6;

        if (boolUsuarioInterno) {
            if (boolAdministrador) {
                numTamanhoMinimoSenha = numTamMinAdministrador || 13;
            } else {
                numTamanhoMinimoSenha = numTamMinInterno || 8;
            }
        }

        // A nova senha:
        // Deve conter pelo menos 6 (seis) caracteres,
        if (strSenha.length < numTamanhoMinimoSenha) {
            return false;
        } // e no m�ximo 30 (trinta) caracteres;

        if (strSenha.length > 30) {
            return false;
        } // Deve conter caracteres de tr�s das quatro seguintes categorias:

        var numCategorias = 0; // caracteres alfab�ticos mai�sculos;
        var boolTemLetraMaiuscula = false;
        var boolTemNumero = false;
        var boolTemCaracterEspecial = false;

        if (/[A-Z]+/.test(strSenha)) {
            numCategorias++;
            boolTemLetraMaiuscula = true;
        } // caracteres alfabéticos maiúsculos

        if (/[a-z]+/.test(strSenha)) {
            numCategorias++;
        } // caracteres alfabéticos minúsculos

        if (/[0-9]+/.test(strSenha)) {
            numCategorias++;
            boolTemNumero = true;
        } // caracteres numéricos

        if (/[!@#\$£%¢¬&\*\(\)\-_\+=§<>\[\]\{},\.;:\/\?\\\|]+/.test(strSenha)) {
            numCategorias++;
            boolTemCaracterEspecial = true;
        } // caracteres especiais, n�o alfab�ticos (por exemplo, !, $, #, %).

        if (boolUsuarioInterno) {
            if (!boolTemLetraMaiuscula || !boolTemNumero || !boolTemCaracterEspecial) {
                return false;
            }
        } else if (numCategorias < 3) {
            return false;
        }

        return true;
    }

    //Função que calcula a data atual mais um número X passado por parâmetro
    function hojeMaisXDias(numDiasDeAcrescimo) {
        var dataAtual = new Date();
        //acrescente o número de dias passados por parâmetro à data atual.
        dataAtual.setDate(dataAtual.getDate() + numDiasDeAcrescimo);
        var dia = (dataAtual.getDate() < 10) ? '0' + dataAtual.getDate() : dataAtual.getDate();
        var mes = ((dataAtual.getMonth() + 1) < 10) ? '0' + (dataAtual.getMonth() + 1) : (dataAtual.getMonth() + 1);
        var ano = dataAtual.getFullYear();
        return dia + '/' + mes + '/' + ano;
    }

    function isMenuAberto() {
        var aberto = false;
        try {
            var wrapper = $("#wrapper");
            aberto = !wrapper.hasClass('toggled');
        } catch (e) {
            console.warn('Erro na função isMenuAberto');
            console.warn(e);
        }
        return aberto;
    }

    var PaginaEproc = {
        getAlturaNavbar: function() {
            return $('#nav').height();
        },
        getTamanhoAreaVisivelConteudo: function() {
            return infraClientHeight() - infraOffsetTopTotal(document.getElementById('divInfraAreaDados'));
        },
        setCookieMenuAberto: function(valor) {
            PaginaEproc.cookie.gravarPrefixado('menu_mostrar', valor, 1);
        },
        isTelaPequena: function() {
            return window.matchMedia("(max-width:768px)").matches;
        },

        cookie: {
            _getPrefixo: function() {
                var prefixo = null;
                var hdnCookie = document.getElementById('hdnInfraPrefixoCookie');

                if (hdnCookie != null) {
                    prefixo = hdnCookie.value;
                }

                return prefixo;
            },
            _gerarNomePrefixado: function(sufixoCookie) {
                var prefix = PaginaEproc.cookie._getPrefixo();
                var nomeCookie = prefix + '_' + sufixoCookie;
                return nomeCookie;
            },
            lerPrefixado: function(sufixoCookie) {
                var nomeCookie = PaginaEproc.cookie._gerarNomePrefixado(sufixoCookie);
                return infraLerCookie(nomeCookie);
            },
            gravarPrefixado: function(sufixoCookie, valor, duracao) {
                var nomeCookie = PaginaEproc.cookie._gerarNomePrefixado(sufixoCookie);
                infraCriarCookie(nomeCookie, valor, duracao);
            },
        },
    };

    /**
     * Recebe um objeto de formulário
     * Devolve seus params naforma de JSON (inclusive multivalorados, na forma de valor como array)
     * @param elem
     * @returns {{}}
     */
    function formToJSON(elem) {
        let output = {};
        if (elem) {
            new FormData(elem).forEach(
                (value, key) => {
                    // Check if property already exist
                    if (Object.prototype.hasOwnProperty.call(output, key)) {
                        let current = output[key];
                        if (!Array.isArray(current)) {
                            // If it's not an array, convert it to an array.
                            current = output[key] = [current];
                        }
                        current.push(value); // Add the new value to the array.
                    } else {
                        output[key] = value;
                    }
                },
            );
        }
        return (output);
    }

    function tempoAnosMesesDias2Segundos(anos, meses, dias) {
        anos = anos * 31556952;

        meses = meses * 2629746;

        dias = dias * 86400;

        return anos + meses + dias;
    }

    function tempoSegundos2AnosMesesDias(segundos) {
        var anos = Math.floor(segundos / 31556952);
        segundos = segundos - (anos * 31556952);

        var meses = Math.floor(segundos / 2629746);
        segundos = segundos - (meses * 2629746);

        var dias = Math.floor(segundos / 86400);
        segundos = segundos - (dias * 86400);

        return {
            'anos': anos,
            'meses': meses,
            'dias': dias,
        };
    }

    //Função que identifica os checkboxes dos processos selecionados, abre os processos em novas abas/janelas,
    //identifica se o navegador está com o popups bloqueados e dá um alerta para o usuário com essa informação.
    function abreProcessosSelecionadosEmAbas() {
        var arrLinksDaLinha;
        var objLinhaSelecionadaComLink = document.getElementsByClassName("infraTrMarcada");
        var bolPopupDesativado = true;
        var strLink;
        var arrLink;
        var arrSepararPorEComercial;
        var strAcao;
        //Sempre busca por essa variável quando setada com processo_selecionar na url
        var arrVariaveisAcaoDaUrl = ['acao=processo_selecionar', 'acao=processo_selecionar_siapro'];
        var strVariaveisDoLink;
        for (let i = 0; i < objLinhaSelecionadaComLink.length; i++) {
            arrLinksDaLinha = objLinhaSelecionadaComLink.item(i).getElementsByTagName("a");
            //percorre todos os links existentes nas linhas marcadas na tabela
            for (let j = 0; j < arrLinksDaLinha.length; j++) {
                strLink = arrLinksDaLinha[j].href;
                arrLink = strLink.split("?");
                strVariaveisDoLink = arrLink[1];
                //acrescentado essa validação, pois caso strVariaveisDoLink for undefined gera erro ao utilizar split
                if (strVariaveisDoLink != undefined) {
                    arrSepararPorEComercial = strVariaveisDoLink.split("&");
                    strAcao = arrSepararPorEComercial[0];
                    for (let k = 0; k < arrVariaveisAcaoDaUrl.length; k++) {
                        if (strAcao == arrVariaveisAcaoDaUrl[k]) {
                            bolPopupDesativado = window.open(arrLinksDaLinha[j].href);
                        }
                    }
                }
            }
        }
        if (!bolPopupDesativado) {
            alert(
                'Seu navegador está bloqueando a abertura dos processos em abas. Favor permitir a abertura de popups para poder utilizar esta ferramenta.')
        }
    }

    function formatSizeUnits(bytes) {
        if (bytes >= 1099511627776) {
            bytes = Math.round(bytes / 1099511627776) + ' TB';
        } else if (bytes >= 1073741824) {
            bytes = Math.round(bytes / 1073741824) + ' GB';
        } else if (bytes >= 1048576) {
            bytes = Math.round(bytes / 1048576) + ' MB';
        } else if (bytes >= 1024) {
            bytes = Math.round(bytes / 1024) + ' KB';
        } else if (bytes >= 1) {
            bytes = Math.round(bytes) + ' bt';
        } else {
            bytes = '';
        }
        return bytes;
    }

    /**
     * funções para formatação de INPUT.TEXT no formato monetário
     */
    String.prototype.currencyFormat = function(f, d, t) {
        var n = (n = this.match(/\d/g)) ? n.join('').replace(/^0+/, '') : '0', f = (f) ? f : 2, d = (d) ? d : ',',
            t                                                                                     = (t) ? t : '.';
        if (n.length < f + 1) {
            return '0' + d + ((n.length < f) ? new Array(f - n.length + 1).join('0') + n : n)
        } else {
            return n.substr(
                    0,
                    n.length - f,
                ).split('').reverse().join('').match(/\d{1,3}/g).join(t).split('').reverse().join('') + d
                + n.substr(n.length
                    - f)
        }
    }

    function onlyNumbers(e) {
        var key;
        var keychar;
        var validChar = '0123456789';

        if (window.event) {
            key = window.event.keyCode;
        } else if (e) {
            key = e.which;
        } else {
            return true;
        }
        keychar = String.fromCharCode(key);

        // control keys
        if (key == null || key == 0 || key == 8 || key == 9 || key == 13 || key == 27) {
            return true;
        }// test the valid keys
        else if ((validChar.indexOf(keychar) == -1)) {
            return false;
        }

        return true;
    }

    var NUM_DIGITOS_CPF = 11;
    var NUM_DIGITOS_CNPJ = 14;
    var NUM_DGT_CNPJ_BASE = 8;

    /**
     * Adiciona método lpad() à classe String. Preenche a String à esquerda com o
     * caractere fornecido, até que ela atinja o tamanho especificado.
     */
    String.prototype.lpad = function(pSize, pCharPad) {
        var str = this;
        var dif = pSize - str.length;
        var ch = String(pCharPad).charAt(0);
        for (; dif > 0; dif--) {
            str = ch + str;
        }
        return (str);
    }

    /**
     * Adiciona método trim() à classe String. Elimina brancos no início e fim da
     * String.
     */
    if (!String.prototype.trim) {
        String.prototype.trim = function() {
            var ret = null;
            ret = this.replace(/^\s*/, "").replace(/\s*$/, "");
            ret = (this == 0 ? "" : this);
            return ret;
        } // String.trim
    }

    /**
     * Calcula os 2 dígitos verificadores para o número-efetivo pEfetivo de CNPJ (12
     * dígitos) ou CPF (9 dígitos) fornecido. pIsCnpj é booleano e informa se o
     * número-efetivo fornecido é CNPJ (default = false).
     *
     * @param String pEfetivo String do número-efetivo (SEM dígitos verificadores) de CNPJ ou CPF.
     * @param boolean pIsCnpj Indica se a string fornecida é de um CNPJ. Caso contrário, é CPF. Default = false (CPF).
     *
     * @return String com os dois dígitos verificadores.
     */
    function dvCpfCnpj(pEfetivo, pIsCnpj) {
        if (pIsCnpj == null) {
            pIsCnpj = false;
        }
        var i, j, k, soma, dv;
        var cicloPeso = pIsCnpj ? NUM_DGT_CNPJ_BASE : NUM_DIGITOS_CPF;
        var maxDigitos = pIsCnpj ? NUM_DIGITOS_CNPJ : NUM_DIGITOS_CPF;
        var calculado = formatCpfCnpj(pEfetivo + "00", false, pIsCnpj);
        calculado = calculado.substring(0, maxDigitos - 2);
        var result = "";

        for (j = 1; j <= 2; j++) {
            k = 2;
            soma = 0;
            for (i = calculado.length - 1; i >= 0; i--) {
                soma += (calculado.charAt(i) - '0') * k;
                k = (k - 1) % cicloPeso + 2;
            }
            dv = 11 - soma % 11;
            if (dv > 9) {
                dv = 0;
            }
            calculado += dv;
            result += dv
        }

        return result;
    } // dvCpfCnpj

    /**
     * Testa se a String pCpf fornecida é um CPF válido. Qualquer formatação que não
     * seja algarismos é desconsiderada.
     *
     * @param String pCpf String fornecida para ser testada.
     *
     * @return <code>true</code> se a String fornecida for um CPF válido.
     */
    function isCpf(pCpf) {
        var numero = formatCpfCnpj(pCpf, false, false);
        if (numero.length > NUM_DIGITOS_CPF) {
            return false;
        }

        var base = numero.substring(0, numero.length - 2);
        var digitos = dvCpfCnpj(base, false);
        var algUnico, i;

        // Valida dígitos verificadores
        if (numero != "" + base + digitos) {
            return false;
        }

        /*
         * Não serão considerados válidos os seguintes CPF: 000.000.000-00,
         * 111.111.111-11, 222.222.222-22, 333.333.333-33, 444.444.444-44,
         * 555.555.555-55, 666.666.666-66, 777.777.777-77, 888.888.888-88,
         * 999.999.999-99.
         */
        algUnico = true;
        for (let i = 1; algUnico && i < NUM_DIGITOS_CPF; i++) {
            algUnico = (numero.charAt(i - 1) == numero.charAt(i));
        }
        return (!algUnico);
    } // isCpf

    /**
     * Testa se a String pCnpj fornecida é um CNPJ válido. Qualquer formatação que
     * não seja algarismos é desconsiderada.
     *
     * @param String pCnpj String fornecida para ser testada.
     *
     * @return <code>true</code> se a String fornecida for um CNPJ válido.
     */
    function isCnpj(pCnpj) {
        var numero = formatCpfCnpj(pCnpj, false, true);
        if (numero.length > NUM_DIGITOS_CNPJ) {
            return false;
        }

        var base = numero.substring(0, NUM_DGT_CNPJ_BASE);
        var ordem = numero.substring(NUM_DGT_CNPJ_BASE, 12);
        var digitos = dvCpfCnpj(base + ordem, true);
        var algUnico;

        // Valida dígitos verificadores
        if (numero != "" + base + ordem + digitos) {
            return false;
        }

        /*
         * Não serão considerados válidos os CNPJ com os seguintes números BÁSICOS:
         * 11.111.111, 22.222.222, 33.333.333, 44.444.444, 55.555.555, 66.666.666,
         * 77.777.777, 88.888.888, 99.999.999.
         */
        algUnico = numero.charAt(0) != '0';
        for (let i = 1; algUnico && i < NUM_DGT_CNPJ_BASE; i++) {
            algUnico = (numero.charAt(i - 1) == numero.charAt(i));
        }

        if (algUnico) {
            return false;
        }

        /*
         * Não será considerado válido CNPJ com número de ORDEM igual a 0000. Não
         * será considerado válido CNPJ com número de ORDEM maior do que 0300 e com
         * as três primeiras posições do número BÁSICO com 000 (zeros). Esta crítica
         * não será feita quando o no BÁSICO do CNPJ for igual a 00.000.000.
         */
        if (ordem == "0000") {
            return false;
        }

        return (base == "00000000" || parseInt(ordem, 10) <= 300 || base.substring(0, 3) != "000");
    } // isCnpj

    addEvent = function(o, e, f, s) {
        var r = o[r = "_" + (e = "on" + e)] = o[r] || (o[e] ? [[o[e], o]] : []), a, c, d;
        r[r.length] = [f, s || o], o[e] = function(e) {
            try {
                (e = e || event).preventDefault || (e.preventDefault = function() {
                    e.returnValue = false;
                });
                e.stopPropagation || (e.stopPropagation = function() {
                    e.cancelBubble = true;
                });
                e.target || (e.target = e.srcElement || null);
                e.key = (e.which + 1 || e.keyCode + 1) - 1 || 0;
            } catch (f) {
            }
            for (d = 1, f = r.length; f; r[--f] && (a = r[f][0], o = r[f][1], a.call
                ? c = a.call(o, e)
                : (o._ = a, c = o._(e), o._ = null), d &= c !== false)) {
                ;
            }
            return e = null, !!d;
        }
    };

    removeEvent = function(o, e, f, s) {
        for (var i = (e = o["_on" + e] || []).length; i;) {
            if (e[--i] && e[i][0] == f && (s || o) == e[i][1]) {
                return delete e[i];
            }
        }
        return false;
    };

    /**
     * Converte a string data para o formato solicitado;
     * IMPORTANTE, o length de cada componente da nova data deve ser IGUAL ao da data antiga
     * @param string oldFormat a - ANO, m-MES, d-DIA. ex: 19840411 aaaammdd, 840411 aammdd
     * @param string newFormat a - ANO, m-MES, d-DIA. ex: 11/04/1984 dd/mm/aaaa, 11-04-84 dd-mm-aa
     *
     * @return [description]
     */
    String.prototype.toFormatDate = function(oldFormat, newFormat) {
        let mes, dia, newDate, iM, iA;
        var ano = mes = dia = newDate = null;
        var iD = iM = iA = 0;

        for (var i = 0; i < oldFormat.length; i++) {
            switch (oldFormat[i]) {
                case 'd':
                    dia = (dia == null ? this[i] : dia += this[i]);
                    break;
                case 'm':
                    mes = (mes == null ? this[i] : mes += this[i]);
                    break;
                case 'a':
                    ano = (ano == null ? this[i] : ano += this[i]);
                    break;
            }
        }

        for (var i = 0; i < newFormat.length; i++) {
            switch (newFormat[i]) {
                case 'd':
                    newDate = (newDate == null ? dia[iD] : newDate += dia[iD]);
                    iD++;
                    break;
                case 'm':
                    newDate = (newDate == null ? mes[iM] : newDate += mes[iM]);
                    iM++;
                    break;
                case 'a':
                    newDate = (newDate == null ? ano[iA] : newDate += ano[iA]);
                    iA++;
                    break;
                default:
                    newDate = (newDate == null ? newFormat[i] : newDate += newFormat[i]);
            }
        }

        return newDate.trim();
    }

    function criarCookieFiltroControleCalculosNCJ() {

        // Pedido 133304: adicionada esta subfunction para tratar os campos ao inserir no cookie
        //                O undefined acontece quando o elemento não é encontrado no HTML
        //                Nesse caso a função coloca um valor padrão
        function addCampo(campo, valorpadrao) {
            if (document.getElementById(campo)) {
                // Pedido 135916
                valorpadrao = $('#' + campo).val();
            }
            return valorpadrao + ";";
        }

        var strCookie = '';

        strCookie += addCampo('txtNumProcesso', 'null');
        strCookie += addCampo('selLocalidade', 'null');

        if (document.getElementById('chkSinMeuProcesso').checked == true) {
            strCookie += 'S;';
        } else {
            strCookie += 'N;';
        }

        // Valores padrão estão de acordo com PaginaEproc.php >> criarCookieFiltroControleCalculosNCJ
        strCookie += addCampo('selTipoSistema', 'null');
        strCookie += addCampo('txtEntrada', '');
        strCookie += addCampo('txtEntradaFim', '');
        strCookie += addCampo('txtSaida', '');
        strCookie += addCampo('txtSaidaFim', '');
        strCookie += addCampo('selOrgao', 'null');
        strCookie += addCampo('selUsuario', 'null');
        strCookie += addCampo('selTipoCalculo', 'null');
        strCookie += addCampo('selTipoSituacaoCalculo', 'null');
        strCookie += addCampo('hdnInfraCampoOrd', 'CodSituacaoCalculo');
        strCookie += addCampo('hdnInfraTipoOrd', 'ASC');

        infraCriarCookie('filtrosControleCalculosNCJ', strCookie, 365);

        return;
    }

    function touch(e) {
        e.preventDefault();
    }

    document.ready = function() {
        (function($) {
            jQuery.fn.pop = function() {
                var top = this.get(-1);
                this.splice(this.length - 1, 1);
                return top;
            };
            jQuery.fn.shift = function() {
                var bottom = this.get(0);
                this.splice(0, 1);
                return bottom;
            };
            jQuery.fn.extend({
                limit: function(limit, input) {
                    var element = '#sp'.concat(input);
                    var interval, f;
                    var self = $(this);

                    $(this).focus(function() {

                        interval = window.setInterval(substring, 100);
                    });

                    $(this).keyup(function() {
                        interval = window.setInterval(substring, 100);

                        var valor = $(self).val();
                        var tamanho = valor.length;
                    });

                    $(this).blur(function() {
                        clearInterval(interval);
                        substring();
                    });

                    let substringFunction = "function substring(){ " +
                        "var val = $(self).val();" +
                        "var tamanho = val.length;" +
                        "var bol = false;" +
                        "if(tamanho > limit){" +
                        "bol = true;" +
                        "$(self).val($(self).val().substring(0,limit));" +
                        "}";
                    if (typeof element != 'undefined') {
                        substringFunction += "if($(element).html() != limit-tamanho){" +
                            "$(element).html((limit-tamanho<=0)?'0':limit-tamanho);" +
                            "strResult = '#hdn'.concat(input);" +
                            "$(strResult).val((limit-tamanho<=0)?'0':limit-tamanho);" +
                            "}";
                    }

                    substringFunction += "if(bol){alert('A descrição do lembrete excedeu " + limit
                        + " caracteres.'); } }";

                    eval(substringFunction);

                    substring();

                },

            });
        })(jQuery);

    }

    function carregarProcessosRelacionados(
        idProcesso,
        seqProcesso,
        bolProcProcesso,
        bolAnalistaProcesso,
        bolGerenteProcesso,
        bolPossuiParteMPF,
        blnChaveProcesso,
        strLink,
        statusProcesso,
        situacaoProcesso,
        numProcesso) {
        var img = "<img src='imagens/loading_pequeno.gif' title='Aguarde' alt='Aguarde' style='width: 1.1em; height: 1.1em;' class='infraImgNormal' border='0'/>";
        document.getElementById('carregarOutrosRelacionados').innerHTML = img;

        $.ajax({
            async: true,
            type: 'POST',
            url: strLink,
            data: {
                idProcesso: idProcesso,
                seqProcesso: seqProcesso,
                bolProcProcesso: bolProcProcesso,
                bolAnalistaProcesso: bolAnalistaProcesso,
                bolGerenteProcesso: bolGerenteProcesso,
                bolPossuiParteMPF: bolPossuiParteMPF,
                blnChaveProcesso: blnChaveProcesso,
                statusProcesso: statusProcesso,
                situacaoProcesso: situacaoProcesso,
                numProcesso: numProcesso,
            },

            success: function(retorno) {

                //**tabela de Processos Relacionados
                var tbl = document.getElementById('tableRelacionado');

                //**deleta a linha do botão '+ e outros'
                // tbl.deleteRow(3);

                //**deleta o tfoot da table (mozilla)
                tbl.deleteTFoot();

                //**tratamento para evitar posições vazias no array de 'TR'
                var arrTrRetorno = new Array();
                console.log(retorno);
                var arrTrRetornoAux = retorno.split('<tr>');
                for (var i = 0; i < arrTrRetornoAux.length; i++) {
                    if (arrTrRetornoAux[i] != " " && arrTrRetornoAux[i] != "") {
                        arrTrRetorno.push(arrTrRetornoAux[i]);
                    }
                }

                var numLinhas = tbl.rows.length;
                for (var i = 0; i < arrTrRetorno.length; i++) {
                    if (i < numLinhas) {
                        tbl.deleteRow(i);
                    }

                    //**insere nova linha
                    var row = tbl.insertRow(i);

                    //**tratamento para evitar posições vazias no array de 'TD'
                    var arrTDRetorno = new Array();
                    var arrTDRetornoAux = arrTrRetorno[i].split('<td>');
                    for (var j = 0; j < arrTDRetornoAux.length; j++) {
                        if (arrTDRetornoAux[j] != "" && arrTDRetornoAux[j] != " ") {
                            arrTDRetorno.push(arrTDRetornoAux[j]);
                        }
                    }

                    //**inserindo o conteúdo na linha nova
                    for (var z = 0; z < arrTDRetorno.length; z++) {
                        row.insertCell(z).innerHTML = arrTDRetorno[z].replace('</td></tr>', '');
                    }
                }

                if ('analisarLinksSeNecessario' in window) {
                    /*
                     * Se a função existir, é porque o usuário optou por abrir links de processos
                     * em uma nova janela/aba.
                     */
                    analisarLinksSeNecessario();
                }
            },
        });
    }

    function validaUsuario(txtUsuarioSigla) {
        var iChars = "!@#$%^&*( )+=[ ]\\\';,/{ } | \":< >?~";

        for (var i = 0; i < txtUsuarioSigla.val().length; i++) {
            if (iChars.indexOf(txtUsuarioSigla.val().charAt(i)) != -1) {
                alert('A sigla: ' + txtUsuarioSigla.val()
                    + ' contem caracteres especiais.\nNÃO são permitidos os caracteres:\n\n' + iChars);
                return false;
            }
        }

        if (txtUsuarioSigla.val().length < 4) {
            alert('Sigla para o usuário não pode ter menos que 4 caracteres.');

            $(document).ready(function() {
                txtUsuarioSigla.focus();
            });

            return false;
        }

        if (txtUsuarioSigla.val() == '' || txtUsuarioSigla.val() == undefined) {
            alert('Informe um Nome de Usuário.');
            return false;
        }

        return true;
    }

    if (!Date.now) {
        Date.now = function now() {
            return (new Date()).getTime();
        }
    }

    function isDataVazia(val) {
        return val == '' || val == '__/__/____';
    }

    function JsonSyntaxHighlight(json) {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(
            /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
            function(match) {
                let cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="json-format-' + cls + '">' + match + '</span>';
            },
        );
    }

    /**
     * Redireciona o usuário para a url [url] incluindo os parâmetros POST [data].
     * Funciona por meio da criação e submissão de um formulário criado on-the-fly.
     * @param url A url para redi
     * @param data
     */
    function redirectWithPostData(url, data) {

        let frm = document.createElement('form')
        frm.id = 'frm_' + Math.random()
        frm.method = 'post'
        frm.action = url
        addDataToForm(frm, data)
        document.body.appendChild(frm)
        frm.submit()
    }

    function addDataToForm(form, data) {
        if (typeof form === 'string') {
            if (form[0] === '#') {
                form = form.slice(1);
            }
            form = document.getElementById(form);
        }

        var keys = Object.keys(data);
        var name;
        var value;
        var input;

        for (var i = 0; i < keys.length; i++) {
            name = keys[i];

            // removing the inputs with the name if already exists [overide]
            Array.prototype.forEach.call(form.elements, function(inpt) {
                if (inpt.name === name) {
                    inpt.parentNode.removeChild(inpt);
                }
            });

            value = data[name];

            if (_.isArray(value)) {
                value.forEach((v, k) => {
                    if (_.isObject(v)) {
                        addObjectInputHiddenToForm(name + '[' + k + ']', v, form);
                    } else {
                        addInputHiddenToForm(name + '[]', v, form);
                    }
                });
            } else if (_.isObject(value)) {
                addObjectInputHiddenToForm(name, value, form);
            } else {
                addInputHiddenToForm(name, value, form);
            }

            function addObjectInputHiddenToForm(name, object, form) {
                for (const [k, v] of Object.entries(object)) {
                    addInputHiddenToForm(name + '[' + k + ']', v, form);
                }
            }

            function addInputHiddenToForm(name, value, form) {
                input = document.createElement('input');
                input.setAttribute('name', name);
                input.setAttribute('value', value);
                input.setAttribute('type', 'hidden');
                form.appendChild(input);
            }
        }
    }

    /**
     * Abre um modal com conteúdo carregado via AJAX/POST.
     * @param url
     * @param params
     * @param idModal
     * @returns {Promise}
     */
    function carregarConteudoModal(url, params, idModal) {
        if (idModal === undefined) {
            idModal = MODAL_SESSAO_JULGAMENTO;
        }

        if (idModal.slice(0, 1) !== '#') {
            idModal = `#${idModal}`;
        }

        const modal = $(idModal);
        const elModalContent = modal.find('.modal-content');
        elModalContent.html('');
        showLoadingOverlayModal(elModalContent);
        modal.modal("show");

        const modalContainer = modal.closest('.has-overlay-modal')
        if (modalContainer.length > 0) {
            $('.modal-backdrop').appendTo(modalContainer)
            $('body').removeClass("modal-open")
            $('body').css("padding-right", "")
        }

        return executarAjaxCarregarConteudoModal(
            url,
            params,
            modal,
        );
    }

    /**
     *
     * @param url string
     * @param params Object
     * @param modal O objeto jQuery ref. ao modal
     * @return Promise
     */
    function executarAjaxCarregarConteudoModal(url, params, modal) {

        var elModalContent = modal.find('.modal-content'); // elemento que receberá o conteudo
        var deferred = $.Deferred(); // vamos devolver uma promise para ser resolvida

        $.ajax({
            url: url,
            method: 'post',
            eprocAlertarErro: true,
            data: params,
            success: function(data) {
                elModalContent.html(data); // alimentando a modal
                deferred.resolve(data); // resolvendo o método
            },
            fail: function() {
                modal.modal('hide'); // em caso de falha, ocultando a modal que estava carregando
                deferred.reject(); // rejeitando o método
            },
        });

        return deferred.promise();
    }

    function showLoadingOverlayModal(container, mensagem) {
        if (mensagem === undefined) {
            mensagem = 'Carregando...';
        }

        var html = "<div class='text-center d-flex align-items-center justify-content-center' style='height: 200px;'>" +
            "       <span class=\"spinner-border text-primary-new\" role=\"status\" style=\"width: 50px;height: 50px;\"></span>\n"
            +
            "       <label class=\"m-0 ml-2 text-primary-new\">" + mensagem + "</label>\n" +
            "</div>"
        var newEl = $(html);
        container.append(newEl);

    }

    /**
     * Executa um AJAX usando a XMLHttpRequest deveolvendo uma promise.
     *
     * @param opts
     * @returns {Promise<unknown>}
     */
    function xhrPromise(opts) {
        let promise = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(
                opts.method || "GET",
                opts.url,
                opts.async || true,
            );

            if (opts.headers) {
                Object.keys(opts.headers).forEach(key => {
                    xhr.setRequestHeader(key, opts.headers[key]);
                });
            }

            xhr.onreadystatechange = function() {
                try {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        if (xhr.readyState === XMLHttpRequest.DONE) {
                            resolve(xhr.response, xhr);
                        }

                        if (xhr.readyState === XMLHttpRequest.LOADING && opts.onReadyStateLoading) {
                            opts.onReadyStateLoading(xhr.response, xhr);
                        }
                    }

                    if (xhr.readyState === XMLHttpRequest.DONE) {
                        resolve(xhr.response, xhr);
                    }
                } catch (err) {
                    reject(err, xhr)
                }
            }
            xhr.onerror = () => reject(xhr.response, xhr);
            xhr.send(opts.body);
        });

        //tratador de erros default
        promise.catch((err, xhr) => {
            eproc.toast.error(err);
            console.log(xhr);
            throw err;
        })

        return promise;
    }

    function ajaxOpenDoc(urlAjax, form, file, type) {
        let ajax = infraAjaxCriarRequest();
        ajax.open("POST", urlAjax, true);
        ajax.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=ISO-8859-1");
        ajax.responseType = 'blob';
        ajax.onreadystatechange = function() {
            if (ajax.readyState === 4) {
                let blob = new window.Blob(
                    [ajax.response],
                    {
                        type: type,
                    },
                );
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = file;
                a.click();
                window.URL.revokeObjectURL(url);
                infraOcultarAviso();
            }
        };
        ajax.send(form.length ? $(form).serialize() : '');
    }

    function ajaxOpenTxt(urlAjax, fileName = 'download', form = '') {
        infraExibirAviso(true, 'Aguarde a geração do arquivo...');
        ajaxOpenDoc(urlAjax, form, fileName + '.txt', "application/text-plain");
    }

    function ajaxOpenXls(urlAjax, form, fileName) {
        infraExibirAviso(true, 'Aguarde a geração da planilha...');
        ajaxOpenDoc(urlAjax, form, fileName + '.xls', "application/vnd.ms-excel");
    }

    // Valida o número do benefício (NB)
    function validarNumeroBeneficio(nb) {
        // retira da string tudo que não é número
        nb = infraRetirarFormatacao(nb);
        // testa se há mais de 10 caracteres
        if (nb.length > 10) {
            return false;
        }
        // completa com zeros a esquerda até o limite de 10 caracteres
        nb = nb.padStart(10, '0');
        // soma
        var total = nb[0] * 2 + nb[1] * 9 + nb[2] * 8 + nb[3] * 7 + nb[4] * 6 + nb[5] * 5 + nb[6] * 4 + nb[7] * 3
            + nb[8] * 2;
        var digito = parseInt(nb[9]);
        // calcula o resto da divisao da lista nmr por 11
        var resto = total % 11;
        // encontra o digito verificador
        var dv = 11 - resto;
        if (dv === 10 || dv === 11) {
            dv = 0;
        }
        return dv === digito;
    }

    window.abrirLink = abrirLink;
    window.adicionarImgVerificado = adicionarImgVerificado;
    window.ajaxExecRequest = ajaxExecRequest;
    window.atualizarSubFrm = atualizarSubFrm;
    window.bolFilho = bolFilho;
    window.converterTempoEmAnosMesesEDiasParaSegundos = converterTempoEmAnosMesesEDiasParaSegundos;
    window.exibirSubFrm = exibirSubFrm;
    window.formatCpfCnpj = formatCpfCnpj;
    window.formToJSON = formToJSON;
    window.hojeMaisXDias = hojeMaisXDias;
    window.inArray = inArray;
    window.inputCopiarNumProcesso = inputCopiarNumProcesso;
    window.inputCopiarTexto = inputCopiarTexto;
    window.copiarConteudoElemetento = copiarConteudoElemetento;
    window.isBlank = isBlank;
    window.isMenuAberto = isMenuAberto;
    window.mascaraFormatoEmHoras = mascaraFormatoEmHoras;
    window.montaStrMinHoras = montaStrMinHoras;
    window.ocultarSubFrm = ocultarSubFrm;
    window.retVar = retVar;
    window.sleep = sleep;
    window.unformatNumber = unformatNumber;
    window.validarNovaSenha = validarNovaSenha;
    window.validarNumeroBeneficio = validarNumeroBeneficio;
    window.eprocJs = eprocJs;
    window.PaginaEproc = PaginaEproc;
    window.tempoAnosMesesDias2Segundos = tempoAnosMesesDias2Segundos;
    window.tempoSegundos2AnosMesesDias = tempoSegundos2AnosMesesDias;
    window.abreProcessosSelecionadosEmAbas = abreProcessosSelecionadosEmAbas;
    window.formatSizeUnits = formatSizeUnits;
    window.onlyNumbers = onlyNumbers;
    window.isCpf = isCpf;
    window.isCnpj = isCnpj;
    window.criarCookieFiltroControleCalculosNCJ = criarCookieFiltroControleCalculosNCJ;
    window.carregarProcessosRelacionados = carregarProcessosRelacionados;
    window.validaUsuario = validaUsuario;
    window.isDataVazia = isDataVazia;
    window.JsonSyntaxHighlight = JsonSyntaxHighlight;
    window.redirectWithPostData = redirectWithPostData;
    window.carregarConteudoModal = carregarConteudoModal;
    window.executarAjaxCarregarConteudoModal = executarAjaxCarregarConteudoModal;
    window.showLoadingOverlayModal = showLoadingOverlayModal;
    window.xhrPromise = xhrPromise;
    window.ajaxOpenXls = ajaxOpenXls;
    window.ajaxOpenTxt = ajaxOpenTxt;
    window.ui = {
        select: {
            enable(id) {
                //todo REMOVER ISTO quando for incorporado � lib UI
                var input = $("#" + id);
                input.prop('disabled', false);
                input.selectpicker('refresh');
            },
            disable(id) {
                //todo REMOVER ISTO quando for incorporado � lib UI
                var input = $("#" + id);
                input.prop('disabled', true);
                input.selectpicker('refresh');
                //todo se houver ajax pendente, cancelar para evitar bugs na ui
            },
            reset(id) {
                $("#" + id).val('');
                $('#' + id).selectpicker('refresh');
            },
        },
    }
})(window);